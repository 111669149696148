body {
  margin: 0;
  padding: 0;
}
.parameters-container {
  padding: 0.5rem;
  top: 0;
  left: 0;
  right: 0;
  background-color: #F3F3F3;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-start;
}

.item {
  min-width: 15vw;
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.button {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #3b629b90;
  color: #F3F3F3;
  font-weight: bolder;
}
